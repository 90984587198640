import React from "react";
import "./Awards.css";

function Awards(props) {
return (
    <>

 <div id="awards">
 <div className="col1"></div>
 
 <div className="awards-and-press">Awards and Press</div>
 <div className="col4">
            <div className="london-design-festiv roobert-medium-vermilion-41px">$10k CAD Financial Grant, May 2022</div>
              <div className="thin-line-less-margin" alt="Line" />
              <div className="london-design-festiv roobert-medium-vermilion-41px">Essen Speil 2019 and Top Drawer 2020 for Misfits</div>
              <div className="thin-line-less-margin" alt="Line" />
              <div className="itv-studios-how-to roobert-medium-vermilion-41px">ITV Studios: How to Spend it Well</div>
              <div className="thin-line-less-margin" alt="Line" />
              <div className="now-play-this-at-som roobert-medium-vermilion-41px">Now Play This at Somerset House</div>
              <div className="thin-line-less-margin" alt="Line" />
              <div className="london-design-festiv-1 roobert-medium-vermilion-41px">London Design Festival 2017</div>
          </div>
          </div>
</>

)

}

export default Awards;
