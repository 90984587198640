import React from "react";
import "./Buzzfeed.css";

function Buzzfeed() {

return (
        <>
        <svg
         className="buzzfeed"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    xmlSpace="preserve"
  >
<path className="st0" d="M12,24c6.6,0,12-5.4,12-12S18.6,0,12,0S0,5.4,0,12S5.4,24,12,24z M8.3,10.7l4.2,2.4L15,8.9l-2.6-1.5l6.5-2.6
	l1,6.9l-2.6-1.5l-3.8,6.5l-4.2-2.4l-2.8,4.9l-2.3-1.3L8.3,10.7z"/>
</svg>
</>
    )}

export default Buzzfeed;