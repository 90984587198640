import React from "react";
import "./GlobeandMail.css";

function GlobeAndMail() {

return (
        <>
        <svg
         className="globeandmail"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 274 274"
    style={{
      enableBackground: "new 0 0 274 274",
    }}
    xmlSpace="preserve"
  >
<path d="M266.1,162.5c14-8.9,23-21.1,22.2-25.2c-2.4-3.1-8.2-2.4-15.5,1.7c-8.2,4-18.9,1.6-24.7-7.4
	c-5.7-9-11.6-7.3-18.9-1.5c-7.4,5.6-22.1,13.8-28.8,0c-4.1-15.5-2.5-11.4,9-32.7c11.6-21.2,14-38.3,14-41.6c0-3.2-1.6-4.9-12.3,0
	c-9,2.4-9.9-43.2-10.7-49.8c0,0,0-12.2-7.4-1.6c-5.8,7.4-28.8,29.3-36.2,31.8c-7.2,2.4-17.9-4.1-16.4-13c0-9-5.8-5.8-8.3,0
	c-2.4,5.6-19.8,61.1-23.8,64.4c-4.2,3.2-7.5,11.4-14.8-2.4c-7.4-14-20.3-46.9-32.4-30.6C58,51.4,36.9,23.1,19.7,22.3
	c-2.5,3.3,1.6,11.4,4.9,13.9c3.2,4,3.2,15.5,0,23.6c-3.4,8.2-3.4,18.8,0.9,28.6c2.4,4.9-0.9,13-14.9,14.7
	c-13.9,1.6-11.4,5.6-5.6,12.9c5.6,7.4,27.1,25.4,32,31.9c4.8,6.6-0.8,14.6-10.7,15.4c-9.9,0.9-18.8-2.4-21.3,3.4
	c-0.9,5.7,7.3,3.2,10.7,8.1c0,0,18,32.6,41.9,31.1c23.8-1.6,24.8,1.4,22.2,11.4c-2.4,8.5-21.6,36.2-39.5,38.3c0,0-7.9-2.4-9,6.5
	c-0.5,3.7,0.8,6.8,5,10.2c0.8,0.6,2.3,1.1,3.4,1.1c16,1.3,34.5-26.3,46.6-47c5.4-9.5,7.6-12.5,10.1-12.5c1.2,0,5.6-0.6,10.4,8.1
	c6.5,11.4,39.5,44.1,81.4,35.1c1.7-4.1-5.8-7.4-10.7-9.9c0,0-17.2-13.9,5-20.2c22.1-5,32.9,3.1,51.8-12.2c0.8-5-9.9-7.5-9.9-7.5
	s-13.1-8,25.4-19.4c18.9-5,18.9-8.2,15.8-10.6C262,174.8,253,171.5,266.1,162.5L266.1,162.5z"/>
</svg>
</>
    )}

export default GlobeAndMail;